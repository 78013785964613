<template>
    <div v-if="items && items.length">
        <template v-if="compact && items.length > 1">
            <div class="is-flex is-justify-content-flex-start is-align-items-center">
                <span>
                    {{ items[0].name }}
                </span>
                <LTooltip
                    :auto-close="['outside', 'escape']"
                    :position="tooltipPosition"
                    multilined
                    size="is-small"
                    type="is-white">
                    <b-tag class="has-text-weight-bold has-text-primary ml-1 is-clickable">
                        +{{ items.length - 1 }}
                    </b-tag>
                    <template #content>
                        <div
                            :style="{ 'max-height': `${ maxHeight }px` }"
                            class="tooltip-content-list">
                            <div
                                :key="index"
                                v-for="(country, index) in items"
                                class="my-1">
                                {{ country.name }}
                            </div>
                        </div>
                    </template>
                </LTooltip>
            </div>
        </template>
        <template v-else>
            <div
                :key="index"
                v-for="(country, index) in items"
                class="is-flex is-justify-content-flex-start is-align-items-center">
                {{ country.name }}
            </div>
        </template>
    </div>
    <div v-else>
        {{ empty ? empty : $options.methods.formatEmptyString() }}
    </div>
</template>

<script>
  import { ListView } from "@core/mixins";

  export default {
    name: "Regions",
    mixins: [ListView],
    props: {
      compact: {
        type: Boolean,
        default: false
      },
      tooltipPosition: {
        type: String,
        default: "is-top"
      },
      maxHeight: {
        type: [String, Number],
        default: 200
      }
    }
  };
</script>

<style lang="scss" scoped>
    .tooltip-content-list {
        margin: -0.35rem -0.75rem;
        padding: 0.35rem 0.75rem;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;
    }
</style>
